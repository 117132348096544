import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { useCallback, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/all';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Header } from '../../Trilhas/Kandan/styles';
import { BoxItemGame } from '../../../components/BoxItemGame';
import { ModalFormQuestionnaires } from './ModalFormOrganizationalClimate';
import {
  IQuestionario,
  IQuestionarioPergunta,
  IQuestionnaireCategory,
} from '../../../interfaces/IQuestionario';
import api from '../../../services/api';
import { ModalFormOrganizationalClimateQuestions } from './ModalFormOrganizationalClimateQuestions';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import { ModalFormAgendamentoQuestionnaire } from './ModalFormAgendamento';
import { ModalQRCodeQuestionario } from '../../Checklist/components/ModalQRCodeQuestionario';
import { BoxItemGameDuplicate } from '../../../components/BoxItemGameDuplicate';

export const CompanyQuiz = () => {
  const { id } = useParams<{ id: string }>();

  const [questionnaires, setQuestionnaires] = useState<IQuestionario[]>([]);
  const [questionnaire, setQuestionnaire] = useState<IQuestionario>();
  const [question, setQuestion] = useState<IQuestionarioPergunta>();
  const [category, setCategory] = useState<IQuestionnaireCategory>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showModalFormViewOnly, setShowModalFormViewOnly] = useState(false);
  const [showModalFormQuestion, setShowModalFormQuestion] = useState(false);
  const [isOpenModalFormAgendamento, setIsOpenModalFormAgendamento] = useState(false);

  const swalOptions: SweetAlertOptions = {
    title: 'Deseja realmente excluir?',
    text: 'Você não será capaz de reverter isso.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ef5350',
    cancelButtonColor: '#757575',
    confirmButtonText: 'Sim, excluir',
    cancelButtonText: 'Cancelar',
    denyButtonColor: '#757575',
  };

  const mapQuestionarioBox = (questionarios: IQuestionario[]): IQuestionario[] => questionarios
    .map((_questionario) => {
      const momentDtLancamento = moment(`${_questionario.dataLancamento}-03:00`).local();

      // extrair para método Utils
      const gmtOffset = momentDtLancamento.utcOffset() / 60;
      const gmt = gmtOffset >= 0 ? `+${gmtOffset}` : gmtOffset;
      const dataLancamento = `${momentDtLancamento.format('DD/MM/YYYY HH:mm')} (GMT ${gmt})`;

      return {
        ..._questionario,
        extraInfo:
        _questionario.dataLancamento !== null
          ? (_questionario?.agendado ? 'Agendado para ' : 'Iniciado em ') + dataLancamento
          : null,
        canEdit: !(_questionario.jaLancado ?? false),
        canDuplicate: true,
        canPreview: _questionario.jaLancado ?? false,
      };
    });

  const fetchOrganizationalClimate = () => {
    api.get(`/questionario-empresa/${id}`).then((response) => {
      if (response.data?.data) {
        setQuestionnaires(mapQuestionarioBox(response.data.data));
      }
    });
  };

  const fetchCategory = () => {
    api.get(`/questionario-empresa/categoria/${id}`).then((response) => {
      if (response.data?.data) {
        setCategory(response.data.data);
      }
    });
  };

  useEffect(() => {
    fetchOrganizationalClimate();
    fetchCategory();
  }, []);

  /**
   * Atualiza os questionarios a cada 30 segundos...
   */
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrganizationalClimate();
    }, 1000 * 30);
    return () => clearInterval(interval);
  });

  const handleOnQuestionnaireSelect = async (item: number) => {
    const climateSelected = questionnaires.find((climate) => climate.id === item);

    setQuestionnaire(climateSelected);

    await api.get(`/questionario-empresa/questionario/${item}`).then((response) => {
      setQuestionnaire(response.data.data);
    });
  };

  const handleOnCloseModal = () => {
    fetchOrganizationalClimate();
    setShowModalForm(false);
    setShowModalFormViewOnly(false);
  };

  const handleOnCloseFormQuestion = (climate?: IQuestionario) => {
    if (climate) setQuestionnaire(climate);
    setShowModalFormQuestion(false);
  };

  const handleDeleteClimate = useCallback((climateId: number) => {
    Swal.fire({
      ...swalOptions,
    }).then(async (result) => {
      if (result.value) {
        try {
          await api.delete(`/questionario-empresa/${climateId}`);
          fetchOrganizationalClimate();
          setQuestionnaire(undefined);
          toast.success('Clima organizacional excluído com sucesso!');
        } catch (error) {
          toast.error('Erro ao excluir clima organizacional!');
        }
      }
    });
  }, []);

  const handleDuplicateQuestionnaire = useCallback((questionnaireId: number) => {
    api
      .post(`/questionario-empresa/duplicar/${questionnaireId}`)
      .then((response) => {
        fetchOrganizationalClimate();
        setQuestionnaire(undefined);
        toast.success('Questinario duplicado com sucesso!');
      })
      .catch((error) => {
        toast.error('Error ao tentar duplicar questionario!');
      });
  }, []);

  const handleDuplicateQuestion = useCallback(
    (questionId: number) => {
      api
        .post(`/questionario-empresa/${questionnaire?.id}/duplicar-questao/${questionId}`)
        .then((response) => {
          fetchOrganizationalClimate();
          setQuestionnaire(response.data.data);
          toast.success('Pergunta duplicada com sucesso!');
        })
        .catch((error) => {
          toast.error('Error ao tentar duplicar pergunta!');
        });
    },
    [questionnaire],
  );

  const handleDeleteClimateQuestion = useCallback(
    (questionId: number) => {
      Swal.fire({
        ...swalOptions,
      }).then(async (result) => {
        if (result.value) {
          try {
            await api.delete(
              `/questionario-empresa/${questionnaire?.id}/questionario/${questionId}`,
            );
            setQuestionnaire((old) => {
              if (old) {
                const newClimate = { ...old };
                newClimate.perguntas = newClimate.perguntas.filter((q) => q.id !== questionId);
                return newClimate;
              }

              return old;
            });
            toast.success('Pergunta excluída com sucesso!');
          } catch (error) {
            toast.error('Erro ao excluir pergunta!');
          }
        }
      });
    },
    [questionnaire],
  );

  const handleDesativarQuestionario = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Você quer realmente desativar?',
        icon: 'warning',
        confirmButtonColor: theme.colors.main,
        input: 'text',
        inputLabel: 'Gostaria de informar o motivo da desativação?',
      }).then((result) => {
        const data = {
          data: {
            motivoDesativacao: result.value,
          },
        };

        api.delete(`/questionario-empresa/${questionnaire?.id}/versao`, data).then(() => {
          setQuestionnaire(undefined);
          fetchOrganizationalClimate();
          toast.success('Questionário desativado!');
        });
      });
    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao desativar questionário!');
      }
    }
  }, [questionnaire]);

  const handleDisableScheduler = () => {
    api
      .post(`/questionario-empresa/${questionnaire?.id}/cancelar-lancamento`)
      .then(() => {
        setQuestionnaire(undefined);
        fetchOrganizationalClimate();
        toast.success('Agendamento cancelado!');
      })
      .catch((error) => {
        if (error.response.data) {
          toast.error(error.response.data.errors.join(','));
        } else {
          toast.error('Erro ao cancelar agendamento!');
        }
      });
  };

  const handleDisplayTitle = (item: any) => {
    if (item.liberado) {
      return (
        <span>
          <strong>{item.titulo}</strong>
          <FaCheckCircle
            size={15}
            color="#4dbd74"
            style={{ marginLeft: '5px', marginTop: '-5px' }}
          />
        </span>
      );
    }
    return <span>{item.titulo}</span>;
  };

  const handleOnQuestionDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      const perguntas = questionnaire?.perguntas || [];
      const [reorderedItem] = perguntas.splice(resultIndex, 1);
      perguntas?.splice(resultDestination, 0, reorderedItem);

      const ordernacaoPerguntas = perguntas
        ?.map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);

      await api.put(
        `questionario-empresa/${questionnaire?.id}/ordenar-perguntas`,
        ordernacaoPerguntas,
      );
    },
    [questionnaire],
  );

  const handleQuestionarioDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setQuestionnaires((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoQuestionario = questionnaires
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put('questionario-empresa/ordenar-questionarios', ordenacaoQuestionario);
      } catch {
        toast.error('Erro ao ordernar checklists');
      }
    },
    [questionnaires],
  );

  return (
    <>
      <ModalQRCodeQuestionario
        isOpen={showLinkModal}
        onClose={() => { setShowLinkModal(false); }}
        questionario={questionnaire}
      />
      {category && (
        <ModalFormQuestionnaires
          showModal={showModalForm || showModalFormViewOnly}
          onCloseModal={handleOnCloseModal}
          questionnaire={questionnaire}
          category={category}
          viewOnly={showModalFormViewOnly}
        />
      )}

      {questionnaire && (
        <>
          <ModalFormOrganizationalClimateQuestions
            showModal={showModalFormQuestion}
            onCloseModal={handleOnCloseFormQuestion}
            climateId={questionnaire.id}
            category={category}
            question={question}
          />

          <ModalFormAgendamentoQuestionnaire
            onClose={() => {
              setIsOpenModalFormAgendamento(false);
              setQuestionnaire(undefined);
              fetchOrganizationalClimate();
            }}
            isOpen={isOpenModalFormAgendamento}
            idQuestionario={questionnaire.id}
          />
        </>
      )}

      <Header className="mt-3">
        <Row>
          <Col md={6} className="col-spacing d-flex">
            <SiCircle size={35} />
            <div style={{ flex: 1 }}>
              <h5>
                Questionários -
                {category?.nome}
              </h5>
              <p>Vamos colocar a mão na massa!</p>
            </div>
          </Col>
          {questionnaire && questionnaire.perguntas.length > 0 && (
            <>
              {!questionnaire.liberado && (
                <>
                  <Col md={6} xs={6} className="d-flex align-items-center">
                    <Button
                      style={{ backgroundColor: '#43af56' }}
                      type="button"
                      color="#fff"
                      className="mt-2"
                      onClick={() => setIsOpenModalFormAgendamento(true)}
                    >
                      Programar lançamento
                    </Button>
                    {questionnaire.agendado && (
                      <Button
                        style={{ backgroundColor: '#e22c2c' }}
                        type="button"
                        color="#fff"
                        className="mt-2"
                        onClick={handleDisableScheduler}
                      >
                        Cancelar lançamento
                      </Button>
                    )}
                  </Col>
                </>
              )}
              {questionnaire.liberado && (
                <Col md={6} xs={6} className="d-flex align-items-center">
                  <Button
                    type="button"
                    color="#fff"
                    className="mt-2"
                    onClick={handleDesativarQuestionario}
                  >
                    Desativar Questionario
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
      </Header>
      <Row>
        <Col md={6}>
          <BoxItemGame
            header="Questionários"
            icon={SiCircle}
            items={questionnaires}
            onShowLinkModal={() => {
              setShowLinkModal(true);
              setQuestionnaire(questionnaire);
            }}
            onShowFormEdit={() => setShowModalForm(true)}
            onShowFormModal={() => {
              setQuestionnaire(undefined);
              setShowModalForm(true);
            }}
            onPreview={() => setShowModalFormViewOnly(true)}
            onDeleteItem={handleDeleteClimate}
            onDuplicateWithId={handleDuplicateQuestionnaire}
            onSelecetItem={handleOnQuestionnaireSelect}
            selectedId={questionnaire?.id}
            displayTitle={handleDisplayTitle}
            superiorSelecionado
            onDragEnd={handleQuestionarioDragEnd}
          />
        </Col>
        <Col md={6}>
          <BoxItemGameDuplicate
            header="Perguntas"
            icon={SiCircle}
            items={questionnaire ? questionnaire.perguntas : []}
            onSelecetItem={(item) => {
              const q = questionnaire?.perguntas.find((pergunta) => pergunta.id === item);
              setQuestion(q);
            }}
            onShowFormEdit={
              !(questionnaire?.liberado || questionnaire?.agendado)
                ? (itemId: number) => {
                  if (questionnaire?.perguntas) {
                    const selected = questionnaire?.perguntas?.find(
                      (selectedItem: any) => selectedItem.id === itemId,
                    );
                    setQuestion(selected);
                    setShowModalFormQuestion(true);
                  }
                }
                : undefined
            }
            onShowFormModal={() => {
              setQuestion(undefined);
              setShowModalFormQuestion(true);
            }}
            onDeleteItem={handleDeleteClimateQuestion}
            onDragEnd={handleOnQuestionDragEnd}
            superiorSelecionado={!!questionnaire}
            disabledButtonAdd={!questionnaire}
            onDuplicateWithId={
              !(questionnaire?.liberado || questionnaire?.agendado)
                ? handleDuplicateQuestion : undefined
            }
          />
        </Col>
      </Row>
    </>
  );
};
